import React, { useEffect, useReducer } from 'react';

type ValidationPropType = {
  children: React.ReactNode;
  onInputsValid?: Function;
  onInputsInvalid?: Function;
};

type ValidationAction = {
  type: 'register' | 'unregister';
  name: string;
  payload: ValidationPayload;
};

type ValidationPayload = {
  hasError?: boolean;
  hasValue?: boolean;
  isRequired?: boolean;
};

export type ValidationParams = {
  active?: boolean;

  required?: boolean;
  minLength?: number;
  maxLength?: number;
  pattern?: RegExp;

  errorMessage: string;
  validationKey?: string;

  backendValidation?: { message: string; code: string };
};

const reducer = (
  state: Map<string, ValidationPayload>,
  action: ValidationAction
): typeof state => {
  switch (action.type) {
    case 'register': {
      const newState = new Map(state);
      newState.set(action.name, action.payload);
      return newState;
    }
    case 'unregister': {
      const newState = new Map(state);

      if (action.name !== undefined && newState.get(action.name)) {
        newState.delete(action.name);
      }

      return newState;
    }
    default: {
      return state;
    }
  }
};

const ValidationDispatchContext = React.createContext({});

const initialReducerState = new Map<string, ValidationPayload>();

function InputValidation({
  children,
  onInputsValid,
  onInputsInvalid,
}: ValidationPropType) {
  const [state, dispatch] = useReducer(reducer, initialReducerState);

  useEffect(() => {
    const errors = Array.from(state).filter(
      ([, payload]) =>
        (!payload.hasValue && payload.isRequired) || payload.hasError
    );

    if (errors.length > 0) {
      onInputsInvalid && onInputsInvalid();
    } else {
      onInputsValid && onInputsValid();
    }
  }, [onInputsValid, onInputsInvalid, state]);

  return (
    <ValidationDispatchContext.Provider value={dispatch}>
      {children}
    </ValidationDispatchContext.Provider>
  );
}

const useInputValidation: () => React.Dispatch<ValidationAction> = () => {
  return React.useContext(
    ValidationDispatchContext
  ) as React.Dispatch<ValidationAction>;
};

export { useInputValidation, InputValidation };
