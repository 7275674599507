import Label from 'Atoms/Label/Label';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { styled } from 'stitches.config';
import { EyeClosedIcon, EyeIcon } from 'Atoms/Icons';
import { timings, animation } from 'Theme/Settings/animation';

import { useInputValidation, ValidationParams } from './InputValidation';
import { doValidation } from './ValidationHelper';

type InputValidationType = {
  validation?: ValidationParams;
  name: string;
};

type InputType = (
  | InputValidationType
  | { validation?: undefined; name?: string }
) & {
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  title: string;
  type?: string;
  disabled?: boolean;
  placeholder?: string;
  newPassword?: boolean;
  autoComplete?: string;
  defaultValue?: string;
  inputBackground?: 'primary6' | 'primary4' | 'primary5';
  noSpace?: boolean;
  upperCase?: boolean;
  inputTextColor?: 'primary' | 'secondary';
  showLabel?: boolean;
};

const Input = ({
  title,
  type = 'Text',
  onChange,
  onBlur,
  onKeyDown,
  name,
  validation,
  disabled,
  placeholder,
  autoComplete,
  newPassword = false,
  defaultValue,
  inputBackground = 'primary6',
  noSpace,
  upperCase,
  inputTextColor = 'primary',
  showLabel = true,
}: InputType) => {
  const validationDispatch = useInputValidation();
  const [hasTouched, setHasTouched] = useState<boolean>(false);
  const [focus, setFocus] = useState<boolean>(false);
  const [hasValue, setHasValue] = useState<boolean>(!!defaultValue);
  const [inputFieldType, setInputFieldType] = useState<string>(type);
  const inputRef = useRef<HTMLInputElement>(null);
  const [errorMessage, setErrorMessage] = useState(
    doValidation(defaultValue || '', validation)
  );

  const showError = useMemo(
    () => hasTouched && !focus && !!errorMessage,
    [errorMessage, focus, hasTouched]
  );

  useEffect(() => {
    if (validation?.backendValidation?.message) {
      setHasTouched(true);
      setErrorMessage(validation.backendValidation.message);
    }
  }, [validation?.backendValidation]);

  const handleChange = useCallback(
    (value: string) => {
      if (value) {
        setHasTouched(true);
      }
      setErrorMessage(doValidation(value, validation));

      setHasValue(!!value);
      onChange && onChange(value);
    },
    [onChange, validation]
  );

  const toggleVisibilityPassword = () => {
    if (type === 'password')
      if (inputFieldType === 'password') {
        setInputFieldType('text');
      } else {
        setInputFieldType('password');
      }
  };

  const passwordHidden = inputFieldType === 'password';

  const onInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocus(false);
    handleChange(e.currentTarget.value);
    onBlur && onBlur(e.currentTarget.value);
  };

  const onInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocus(true);
    setHasTouched(true);
  };

  useEffect(() => {
    if (!validation) return;

    validationDispatch({
      type: 'register',
      name: name || '',
      payload: {
        hasError: !!errorMessage,
        hasValue: hasValue,
        isRequired: !!validation.required,
      },
    });

    return () =>
      validationDispatch({ type: 'unregister', name: name || '', payload: {} });
  }, [
    validationDispatch,
    name,
    hasTouched,
    errorMessage,
    hasValue,
    validation,
  ]);

  const formInputStyle = {
    borderColor: showError ? '$errorColor' : '$secondary4',
    color: showError ? '$errorColor' : '$secondary4',
  };

  //to make validation work on autofill
  useEffect(() => {
    let interval = setInterval(() => {
      if (
        inputRef.current &&
        inputRef.current.value !== '' &&
        document.activeElement !== inputRef.current
      ) {
        doValidation(inputRef.current.value, validation);
        //do the same for all autofilled fields
        clearInterval(interval);
      } else {
        clearInterval(interval);
      }
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef.current?.value]);

  const getLabelStyle = () => {
    let cssStyle = {};
    if (focus) {
      cssStyle = labelCss;
    } else {
      cssStyle = { ...labelCss, color: '$secondary4' };
    }

    if (showError) {
      cssStyle = {
        ...labelCss,
        borderColor: '$errorColor',
        color: '$errorColor',
      };
    }

    return { ...cssStyle, backgroundColor: `$${inputBackground}` };
  };

  return (
    <InputGroup spacing={noSpace ? 'noSpace' : 'space'}>
      {(focus || hasValue || showError) && showLabel && (
        <Label hasError={showError} css={getLabelStyle()}>
          {title}
        </Label>
      )}

      <FormInput
        spacing={noSpace ? 'noSpace' : 'space'}
        casing={upperCase ? 'upper' : 'auto'}
        ref={inputRef}
        type={inputFieldType}
        onChange={(e) =>
          handleChange(
            upperCase
              ? e.currentTarget.value.toLocaleUpperCase()
              : e.currentTarget.value
          )
        }
        {...(showError && { error: true })}
        {...(onKeyDown && { onKeyDown })}
        onBlur={onInputBlur}
        onFocus={onInputFocus}
        name={name}
        autoComplete={newPassword ? 'new-password' : autoComplete}
        placeholder={placeholder}
        disabled={disabled}
        style={formInputStyle}
        id={name}
        defaultValue={defaultValue}
        inputBackground={inputBackground}
        inputTextColor={inputTextColor}
        maxLength={1000}
      />

      {type === 'password' && (
        <TogglePasswordIcon
          onClick={() => {
            toggleVisibilityPassword();
          }}
        >
          {passwordHidden ? (
            <EyeClosedIcon size="s" color="secondary" />
          ) : (
            <EyeIcon size="s" color="secondary" />
          )}
        </TogglePasswordIcon>
      )}

      {showError && <ErrorText>{errorMessage}</ErrorText>}
    </InputGroup>
  );
};

const InputGroup = styled('div', {
  position: 'relative',
  variants: {
    spacing: {
      space: {
        mb: 2,
      },
      noSpace: {},
    },
  },
});

const TogglePasswordIcon = styled('span', {
  position: 'absolute',
  top: '14px',
  r: 1,
  p: 2,
  cursor: 'pointer',
});

const fontStyleCss = {
  fontFamily: '$fontSecondary400',
  letterSpacing: '$ls0',
};

const ErrorText = styled('div', {
  ...fontStyleCss,
  fs: 6,
  lineHeight: '$lh133',
  color: '$errorColor',
  ml: '15px',
});

const labelCss = {
  ...fontStyleCss,
  color: '$JE68GreenPrimary',
  top: 2,
  px: 1,
  fs: 5,
  lineHeight: '$lh12',
};

const FormInput = styled('input', {
  ...fontStyleCss,
  lineHeight: 'lh1125',
  fs: 8,
  w: '100%',
  h: 11,
  px: 4,
  py: 3,
  bw: 0.25,
  borderRadius: 'unset',
  borderStyle: 'solid',
  borderColor: '$inputBorderColor',
  color: '$inputText',
  '&:focus': {
    outline: 'none',
    borderColor: '$JE68GreenPrimary',
  },
  '&::placeholder': {
    WebkitTextFillColor: '$colors$inputPlaceholderText !important',
    opacity: 1,
    transition: `opacity, opacity ${timings.threeTenths} ${animation.timingFn}`,
  },
  '&:-webkit-autofill,': {
    WebkitTextFillColor: '$colors$inputText !important',
    caretColor: '$colors$inputText',
  },
  '&:disabled': {
    WebkitBoxShadow: `0 0 0 1000px rgba(0, 0, 0, 0) inset !important`,
    borderColor: '$inputDisabledBorderColor',
    backgroundColor: '$inputDisabledbackground',
    color: '$inputDisabledText',
  },
  '&:focus::placeholder': {
    opacity: 0,
    transition: `opacity ${timings.threeTenths} ${animation.timingFn}`,
  },
  '&::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
  variants: {
    casing: {
      upper: {
        textTransform: 'uppercase',
      },
      auto: {},
    },
    spacing: {
      space: {
        mt: 2,
        mb: 1,
      },
      noSpace: {
        height: '100%',
      },
    },
    error: {
      true: {
        color: '$errorColor',
        borderColor: '$errorColor',
      },
    },
    inputBackground: {
      primary6: {
        backgroundColor: '$inputBackground',
        '&:-webkit-autofill,': {
          WebkitBoxShadow: `0 0 0 1000px $colors$inputBackground inset !important`,
        },
      },
      primary4: {
        backgroundColor: '$primary4',
        '&:-webkit-autofill,': {
          WebkitBoxShadow: `0 0 0 1000px $colors$primary4 inset !important`,
        },
      },
      primary5: {
        backgroundColor: '$primary5',
        '&:-webkit-autofill,': {
          WebkitBoxShadow: `0 0 0 1000px $colors$primary5 inset !important`,
        },
      },
    },
    inputTextColor: {
      primary: {
        color: '$colors$inputText',
        '&:-webkit-autofill,': {
          WebkitTextFillColor: '$colors$inputText !important',
          caretColor: '$colors$inputText',
        },
      },
      secondary: {
        color: '$JE68GreenPrimary',
        '&:-webkit-autofill,': {
          WebkitTextFillColor: '$colors$JE68GreenPrimary !important',
          caretColor: '$colors$JE68GreenPrimary',
        },
      },
    },
  },
});

export default Input;
