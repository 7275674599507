import { styled } from 'stitches.config';

type ProductCardTitleTypes = {
  title: string;
  size?: 's' | 'm';
  onClick?: () => void;
};
const ProductCardTitle = ({
  title,
  size = 'm',
  onClick,
}: ProductCardTitleTypes) => {
  return (
    <Title size={size} onClick={onClick}>
      {title}
    </Title>
  );
};

const Title = styled('span', {
  fontFamily: '$fontSecondary600',
  color: '$cartItemText',
  letterSpacing: 0,
  variants: {
    size: {
      s: {
        fs: 7,
        lineHeight: '$lh125',
      },
      m: {
        fs: 7,
        lineHeight: '$lh125',
        '@mediaMinLarge': {
          fs: 10,
          lineHeight: '$lh12',
        },
      },
    },
  },
});

export default ProductCardTitle;
