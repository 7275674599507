import FavoriteModel from 'Shared/Favorites/FavoriteModel.interface';
import { useEffect, useState } from 'react';
import Fetcher from 'Shared/Common/Fetcher';
import { IS_PRODUCTION_ENV } from 'Shared/Configs/EnvConfig';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import { useUserStateData } from 'Shared/Providers/UserContextProvider';
import useSWR, { mutate } from 'swr';

const favoriteApiUrl = '/api/favorites';

let abortController: AbortController = new AbortController();
let hasMounted = false;

type FavoriteReturnType = {
  favoriteList: FavoriteModel;
  hasFavorites: boolean;
  isLoading: boolean;
};

export function GetFavorites(languageRoute: string): FavoriteReturnType {
  const tempFavoriteObject: FavoriteModel = {} as FavoriteModel;
  const [favoriteList, setFavoriteList] =
    useState<FavoriteModel>(tempFavoriteObject);
  const [hasFavorites, setHasFavorites] = useState<boolean>(false);
  const { pageCacheTime } = useAppSettingsData();
  const {
    user: { authenticated },
  } = useUserStateData();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // condition - fetch only if authenticated
  const { data: fetchedCart } = useSWR(
    authenticated
      ? `${favoriteApiUrl}/GetFavorites?language=${languageRoute}`
      : null,
    FetchFavorites,
    {
      fallbackData: undefined,
      revalidateOnFocus: IS_PRODUCTION_ENV,
      dedupingInterval: pageCacheTime,
    }
  );

  useEffect(() => {
    if (!hasMounted) {
      hasMounted = true;
    } else {
      if (fetchedCart) {
        const checkFavoritesLength =
          fetchedCart.favoriteMotorcycleConfigurations.length > 0 ||
          fetchedCart.favoriteProducts.length > 0;

        setFavoriteList(fetchedCart);

        setHasFavorites(checkFavoritesLength);

        setIsLoading(false);
      }
    }
  }, [fetchedCart]);

  return { favoriteList, hasFavorites, isLoading };
}

export async function AddFavorite(code: string, languageRoute: string) {
  const res = await fetch(
    `${favoriteApiUrl}/AddFavorite?code=${code}&language=${languageRoute}`,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
    }
  );

  if (res.ok) {
    const { favorites } = await res.json();
    mutate(
      `${favoriteApiUrl}/GetFavorites?language=${languageRoute}`,
      favorites,
      false
    );
  }
}

export async function RemoveFavorite(code: string, languageRoute: string) {
  const res = await fetch(
    `${favoriteApiUrl}/RemoveFavorite?code=${code}&language=${languageRoute}`,
    {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
    }
  );

  if (res.ok) {
    const { favorites } = await res.json();
    mutate(
      `${favoriteApiUrl}/GetFavorites?language=${languageRoute}`,
      favorites,
      false
    );
  }
}

export async function AddFavoriteConfigurator(
  cartName: string,
  languageRoute: string
) {
  const res = await fetch(
    `${favoriteApiUrl}/AddMotorcycleConfiguration?motorcycleConfiguratorCartName=${cartName}&language=${languageRoute}`,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
    }
  );

  if (res.ok) {
    const { favorites } = await res.json();
    mutate(
      `${favoriteApiUrl}/GetFavorites?language=${languageRoute}`,
      favorites,
      false
    );
  }
}

export async function RemoveFavoriteConfigurator(
  cartName: string,
  languageRoute: string
) {
  const res = await fetch(
    `${favoriteApiUrl}/RemoveMotorcycleConfiguration?motorcycleConfiguratorCartName=${cartName}&language=${languageRoute}`,
    {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
    }
  );

  if (res.ok) {
    const { favorites } = await res.json();
    mutate(
      `${favoriteApiUrl}/GetFavorites?language=${languageRoute}`,
      favorites,
      false
    );
  }
}

function FetchFavorites(url: string) {
  abortController.abort();
  abortController = new AbortController();
  const signal = abortController.signal;

  return Fetcher<FavoriteModel, any>(url, signal, (data, resolve) => {
    resolve(data.favorites);
  });
}
