import { ValidationParams } from './InputValidation';

export const doValidation = (
  currentValue: string,
  validation: ValidationParams | undefined
): string | null => {
  if (!validation) {
    return null;
  }

  let validationError = false;

  if (validation.active !== undefined && !validation.active) {
    return null;
  }

  if (validation.backendValidation) {
    return validation.backendValidation.message;
  }
  if (validation.pattern) {
    if (!validation.pattern.test(currentValue)) {
      validationError = true;
    }
  }
  if (validation.required) {
    if (!currentValue) {
      validationError = true;
    }
  }
  if (validation.minLength) {
    if (
      !currentValue ||
      (currentValue && currentValue.length < validation.minLength)
    ) {
      validationError = true;
    }
  }
  if (validation.maxLength) {
    if (currentValue && currentValue.length > validation.maxLength) {
      validationError = true;
    }
  }

  return validationError ? validation.errorMessage : null;
};
