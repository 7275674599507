import { CSS } from '@stitches/react';
import { styled } from 'stitches.config';

type PropTypes = {
  children: React.ReactNode;
  backgroundColorVariants: 'primary' | 'secondary' | 'secondaryDark';
  large?: boolean;
  css?: CSS;
};

function CircleBackground({
  children,
  backgroundColorVariants,
  large,
  css,
}: PropTypes) {
  return (
    <CircleContainer
      backgroundColorVariants={backgroundColorVariants}
      large={large}
      css={css}
    >
      {children}
    </CircleContainer>
  );
}

const CircleContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexShrink: 0,
  br: '50%',
  mr: 2,
  marginTop: '5px',
  wh: 4,
  variants: {
    backgroundColorVariants: {
      primary: {
        backgroundColor: '$colors$JE68GreenPrimary',
        '& svg': { stroke: '$primary6' },
      },
      secondary: {
        backgroundColor: '$primaryLight2',
        '& svg': { stroke: '$primary6' },
      },
      secondaryDark: {
        backgroundColor: '$primary1',
        '& svg': { stroke: '$pureWhite' },
      },
    },
    large: {
      true: { wh: 6 },
    },
  },
  defaultVariants: {
    backgroundColorVariants: 'secondaryDark',
  },
});

export default CircleBackground;
