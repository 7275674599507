/* eslint-disable */

export const accountFormValidation = {
  password: {
    minLength: 8,
    maxLength: 60,
    pattern:
      /^(?=.*?[A-Z\u00c0-\u00DF])(?=.*?[a-z\u00e0-\u017e])(?=.*?[0-9])(?=.*?[!@#$%^&*\(\)_\+\-\={}<>,\.\|""'~`:;\\?\/\[\] ]).{8,60}$/,
  },
  personnumber: {
    pattern: /^(\d{6}|\d{8})[-|(\s)]{0,1}\d{4}$/,
  },
  email: {
    required: true,
    minLength: 1,
    maxLength: 40,
    pattern:
      /^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*(\+[a-zA-Z0-9-]+)?@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*$/,
  },
  phoneNumber: {
    pattern: /(^[\\s\-+()0-9]{0,16}$)/,
  },
};
