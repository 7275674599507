import React from 'react';
import { styled } from 'stitches.config';
import { CSS } from '@stitches/react';

type LabelType = {
  hasError: boolean;
  children: React.ReactNode;
  css?: CSS;
  htmlFor?: string;
};

const Label = ({ hasError, children, css, htmlFor }: LabelType) => (
  <FormLabel htmlFor={htmlFor} css={css} color={hasError ? 'error' : 'default'}>
    {children}
  </FormLabel>
);

const FormLabel = styled('label', {
  fs: 5,
  fontWeight: '$fw700',
  mx: 3,
  px: 3,
  position: 'absolute',
  backgroundColor: '$inputGroupPrimaryBackground',
  variants: {
    color: {
      default: {
        color: 'inherit',
      },
      error: {
        color: '$errorText',
      },
    },
  },
});

export default Label;
