import KexIconLoader from 'Kex/KexIconLoader';
import { styled } from 'stitches.config';
import CircleBackground from '../Icons/CircleBackground';
import { CSS } from '@stitches/react';

type PropType = {
  text: string;
  css?: CSS;
  backgroundColor: 'primary' | 'secondary' | 'secondaryDark';
  large?: boolean;
  textCSS?: CSS;
};

const Icon = KexIconLoader('Checkmark');

function BulletPoint({
  text,
  css,
  backgroundColor,
  large = false,
  textCSS,
}: PropType) {
  return (
    <BulletWrapper css={css}>
      <TextWrapper css={textCSS}>
        <CircleBackground
          backgroundColorVariants={backgroundColor}
          large={large}
          css={{ mt: 0 }}
        >
          <Icon size={large ? 's' : 'xs'} />
        </CircleBackground>
        {text}
      </TextWrapper>
    </BulletWrapper>
  );
}

export default BulletPoint;

const BulletWrapper = styled('div', {});
const TextWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  color: '$textPrimary',
  fontFamily: '$fontSecondary400',
});
